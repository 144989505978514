.lg-sub-html, .lg-toolbar {
  background-color: #00000073;
}

#lg-counter, .lg-outer .lg-video-cont {
  vertical-align: middle;
  display: inline-block;
}

@font-face {
  font-family: lg;
  src: url("lg.9cf70984.eot");
  src: url("lg.9cf70984.eot#iefixn1z373") format("embedded-opentype"), url("lg.0df9f26c.woff") format("woff"), url("lg.e18f1a3b.ttf") format("truetype"), url("lg.2d525ca9.svg#lg") format("svg");
  font-weight: 400;
  font-style: normal;
}

.lg-icon {
  speak: none;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: lg;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
}

.lg-actions .lg-next, .lg-actions .lg-prev {
  color: #999;
  cursor: pointer;
  z-index: 1080;
  background-color: #00000073;
  border-radius: 2px;
  margin-top: -10px;
  padding: 8px 10px 9px;
  font-size: 22px;
  display: block;
  position: absolute;
  top: 50%;
}

.lg-actions .lg-next.disabled, .lg-actions .lg-prev.disabled {
  pointer-events: none;
  opacity: .5;
}

.lg-actions .lg-next:hover, .lg-actions .lg-prev:hover {
  color: #fff;
}

.lg-actions .lg-next {
  right: 20px;
}

.lg-actions .lg-next:before {
  content: "";
}

.lg-actions .lg-prev {
  left: 20px;
}

.lg-actions .lg-prev:after {
  content: "";
}

@keyframes lg-right-end {
  0%, 100% {
    left: 0;
  }

  50% {
    left: -30px;
  }
}

@keyframes lg-left-end {
  0%, 100% {
    left: 0;
  }

  50% {
    left: 30px;
  }
}

.lg-outer.lg-right-end .lg-object {
  animation: .3s lg-right-end;
  position: relative;
}

.lg-outer.lg-left-end .lg-object {
  animation: .3s lg-left-end;
  position: relative;
}

.lg-toolbar {
  z-index: 1082;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.lg-toolbar .lg-icon {
  color: #999;
  cursor: pointer;
  float: right;
  height: 47px;
  text-align: center;
  width: 50px;
  -o-transition: color .2s linear;
  outline: 0;
  padding: 10px 0;
  font-size: 24px;
  line-height: 27px;
  transition: color .2s linear;
  text-decoration: none !important;
}

.lg-toolbar .lg-icon:hover {
  color: #fff;
}

.lg-toolbar .lg-close:after {
  content: "";
}

.lg-toolbar .lg-download:after {
  content: "";
}

.lg-sub-html {
  color: #eee;
  text-align: center;
  z-index: 1080;
  padding: 10px 40px;
  font-size: 16px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.lg-sub-html h4 {
  margin: 0;
  font-size: 13px;
  font-weight: 700;
}

.lg-sub-html p {
  margin: 5px 0 0;
  font-size: 12px;
}

#lg-counter {
  color: #999;
  padding-top: 12px;
  padding-left: 20px;
  font-size: 16px;
}

.lg-next, .lg-prev, .lg-toolbar {
  opacity: 1;
  -webkit-transition: -webkit-transform .35s cubic-bezier(0, 0, .25, 1), opacity .35s cubic-bezier(0, 0, .25, 1), color .2s linear;
  -o-transition: -o-transform .35s cubic-bezier(0, 0, .25, 1) 0s, opacity .35s cubic-bezier(0, 0, .25, 1) 0s, color .2s linear;
  -moz-transition: -moz-transform .35s cubic-bezier(0, 0, .25, 1), opacity .35s cubic-bezier(0, 0, .25, 1), color .2s linear;
  transition: transform .35s cubic-bezier(0, 0, .25, 1), opacity .35s cubic-bezier(0, 0, .25, 1), color .2s linear;
}

.lg-hide-items .lg-prev {
  opacity: 0;
  transform: translate3d(-10px, 0, 0);
}

.lg-hide-items .lg-next {
  opacity: 0;
  transform: translate3d(10px, 0, 0);
}

.lg-hide-items .lg-toolbar {
  opacity: 0;
  transform: translate3d(0, -10px, 0);
}

body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-object {
  opacity: 0;
  transform-origin: 50%;
  transform: scale3d(.5, .5, .5);
  -webkit-transition: -webkit-transform .25s cubic-bezier(0, 0, .25, 1), opacity .25s cubic-bezier(0, 0, .25, 1) !important;
  -o-transition: -o-transform .25s cubic-bezier(0, 0, .25, 1) 0s, opacity .25s cubic-bezier(0, 0, .25, 1) !important;
  -moz-transition: -moz-transform .25s cubic-bezier(0, 0, .25, 1), opacity .25s cubic-bezier(0, 0, .25, 1) !important;
  transition: transform .25s cubic-bezier(0, 0, .25, 1), opacity .25s cubic-bezier(0, 0, .25, 1) !important;
}

body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-item.lg-complete .lg-object {
  opacity: 1;
  transform: scale3d(1, 1, 1);
}

.lg-outer .lg-thumb-outer {
  width: 100%;
  z-index: 1080;
  max-height: 350px;
  -webkit-transition: -webkit-transform .25s cubic-bezier(0, 0, .25, 1);
  -o-transition: -o-transform .25s cubic-bezier(0, 0, .25, 1) 0s;
  -moz-transition: -moz-transform .25s cubic-bezier(0, 0, .25, 1);
  background-color: #0d0a0a;
  transition: transform .25s cubic-bezier(0, 0, .25, 1);
  position: absolute;
  bottom: 0;
  transform: translate3d(0, 100%, 0);
}

.lg-outer .lg-thumb-outer.lg-grab .lg-thumb-item {
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: -o-grab;
  cursor: -ms-grab;
  cursor: grab;
}

.lg-outer .lg-thumb-outer.lg-grabbing .lg-thumb-item {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: -o-grabbing;
  cursor: -ms-grabbing;
  cursor: grabbing;
}

.lg-outer .lg-thumb-outer.lg-dragging .lg-thumb {
  transition-duration: 0s !important;
}

.lg-outer.lg-thumb-open .lg-thumb-outer {
  transform: translate3d(0, 0, 0);
}

.lg-outer .lg-thumb {
  height: 100%;
  margin-bottom: -5px;
  padding: 10px 0;
}

.lg-outer .lg-thumb-item {
  cursor: pointer;
  float: left;
  height: 100%;
  border: 2px solid #fff;
  border-radius: 4px;
  margin-bottom: 5px;
  overflow: hidden;
}

@media (min-width: 1025px) {
  .lg-outer .lg-thumb-item {
    -o-transition: border-color .25s ease;
    transition: border-color .25s;
  }
}

.lg-outer .lg-thumb-item.active, .lg-outer .lg-thumb-item:hover {
  border-color: #a90707;
}

.lg-outer .lg-thumb-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.lg-outer.lg-has-thumb .lg-item {
  padding-bottom: 120px;
}

.lg-outer.lg-can-toggle .lg-item {
  padding-bottom: 0;
}

.lg-outer.lg-pull-caption-up .lg-sub-html {
  -o-transition: bottom .25s ease;
  transition: bottom .25s;
}

.lg-outer.lg-pull-caption-up.lg-thumb-open .lg-sub-html {
  bottom: 100px;
}

.lg-outer .lg-toggle-thumb {
  color: #999;
  cursor: pointer;
  height: 39px;
  text-align: center;
  width: 50px;
  background-color: #0d0a0a;
  border-radius: 2px 2px 0 0;
  padding: 5px 0;
  font-size: 24px;
  line-height: 27px;
  position: absolute;
  top: -39px;
  right: 20px;
}

.lg-outer .lg-toggle-thumb:hover, .lg-outer.lg-dropdown-active #lg-share {
  color: #fff;
}

.lg-outer .lg-toggle-thumb:after {
  content: "";
}

.lg-outer .lg-video-cont {
  max-width: 1140px;
  max-height: 100%;
  width: 100%;
  padding: 0 5px;
}

.lg-outer .lg-video {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
  overflow: hidden;
}

.lg-outer .lg-video .lg-object {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}

.lg-outer .lg-video .lg-video-play {
  width: 84px;
  height: 59px;
  z-index: 1080;
  cursor: pointer;
  margin-top: -30px;
  margin-left: -42px;
  position: absolute;
  top: 50%;
  left: 50%;
}

.lg-outer .lg-has-vimeo .lg-video-play {
  background: url("vimeo-play.1e49092f.png") no-repeat;
}

.lg-outer .lg-has-vimeo:hover .lg-video-play {
  background: url("vimeo-play.1e49092f.png") 0 -58px no-repeat;
}

.lg-outer .lg-has-html5 .lg-video-play {
  height: 64px;
  width: 64px;
  opacity: .8;
  background: url("video-play.fee54261.png") no-repeat;
  margin-top: -32px;
  margin-left: -32px;
}

.lg-outer .lg-has-html5:hover .lg-video-play {
  opacity: 1;
}

.lg-outer .lg-has-youtube .lg-video-play {
  background: url("youtube-play.0b8fad0d.png") no-repeat;
}

.lg-outer .lg-has-youtube:hover .lg-video-play {
  background: url("youtube-play.0b8fad0d.png") 0 -60px no-repeat;
}

.lg-outer .lg-video-object {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}

.lg-outer .lg-has-video .lg-video-object {
  visibility: hidden;
}

.lg-outer .lg-has-video.lg-video-playing .lg-object, .lg-outer .lg-has-video.lg-video-playing .lg-video-play {
  display: none;
}

.lg-outer .lg-has-video.lg-video-playing .lg-video-object {
  visibility: visible;
}

.lg-progress-bar {
  height: 5px;
  width: 100%;
  z-index: 1083;
  opacity: 0;
  -o-transition: opacity 80ms ease 0s;
  background-color: #333;
  transition: opacity 80ms;
  position: absolute;
  top: 0;
  left: 0;
}

.lg-progress-bar .lg-progress {
  height: 5px;
  width: 0;
  background-color: #a90707;
}

.lg-progress-bar.lg-start .lg-progress {
  width: 100%;
}

.lg-show-autoplay .lg-progress-bar {
  opacity: 1;
}

.lg-autoplay-button:after {
  content: "";
}

.lg-show-autoplay .lg-autoplay-button:after {
  content: "";
}

.lg-outer.lg-css3.lg-zoom-dragging .lg-item.lg-complete.lg-zoomable .lg-image, .lg-outer.lg-css3.lg-zoom-dragging .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
  transition-duration: 0s;
}

.lg-outer.lg-use-transition-for-zoom .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
  -webkit-transition: -webkit-transform .3s cubic-bezier(0, 0, .25, 1);
  -o-transition: -o-transform .3s cubic-bezier(0, 0, .25, 1) 0s;
  -moz-transition: -moz-transform .3s cubic-bezier(0, 0, .25, 1);
  transition: transform .3s cubic-bezier(0, 0, .25, 1);
}

.lg-outer.lg-use-left-for-zoom .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
  -o-transition: left .3s cubic-bezier(0, 0, .25, 1) 0s, top .3s cubic-bezier(0, 0, .25, 1) 0s;
  transition: left .3s cubic-bezier(0, 0, .25, 1), top .3s cubic-bezier(0, 0, .25, 1);
}

.lg-outer .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
}

.lg-outer .lg-item.lg-complete.lg-zoomable .lg-image {
  transform-origin: 0 0;
  backface-visibility: hidden;
  transform: scale3d(1, 1, 1);
  -webkit-transition: -webkit-transform .3s cubic-bezier(0, 0, .25, 1), opacity .15s !important;
  -o-transition: -o-transform .3s cubic-bezier(0, 0, .25, 1) 0s, opacity .15s !important;
  -moz-transition: -moz-transform .3s cubic-bezier(0, 0, .25, 1), opacity .15s !important;
  transition: transform .3s cubic-bezier(0, 0, .25, 1), opacity .15s !important;
}

#lg-zoom-in:after {
  content: "";
}

#lg-actual-size {
  font-size: 20px;
}

#lg-actual-size:after {
  content: "";
}

#lg-zoom-out {
  opacity: .5;
  pointer-events: none;
}

#lg-zoom-out:after {
  content: "";
}

.lg-zoomed #lg-zoom-out {
  opacity: 1;
  pointer-events: auto;
}

.lg-outer .lg-pager-outer {
  text-align: center;
  z-index: 1080;
  height: 10px;
  position: absolute;
  bottom: 60px;
  left: 0;
  right: 0;
}

.lg-outer .lg-pager-outer.lg-pager-hover .lg-pager-cont {
  overflow: visible;
}

.lg-outer .lg-pager-cont {
  cursor: pointer;
  vertical-align: top;
  margin: 0 5px;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.lg-outer .lg-pager-cont:hover .lg-pager-thumb-cont {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.lg-outer .lg-pager-cont.lg-pager-active .lg-pager {
  box-shadow: inset 0 0 0 2px #fff;
}

.lg-outer .lg-pager-thumb-cont {
  color: #fff;
  height: 83px;
  opacity: 0;
  width: 120px;
  -webkit-transition: opacity .15s, -webkit-transform .15s;
  -o-transition: opacity .15s ease 0s, -o-transform .15s ease 0s;
  -moz-transition: opacity .15s, -moz-transform .15s;
  background-color: #fff;
  border-radius: 3px;
  margin-bottom: 20px;
  margin-left: -60px;
  padding: 5px;
  transition: opacity .15s, transform .15s;
  position: absolute;
  bottom: 100%;
  left: 0;
  transform: translate3d(0, 5px, 0);
}

.lg-outer .lg-pager-thumb-cont img {
  width: 100%;
  height: 100%;
}

.lg-outer .lg-pager {
  height: 12px;
  -o-transition: box-shadow .3s ease 0s;
  width: 12px;
  background-color: #ffffff80;
  border-radius: 50%;
  transition: box-shadow .3s;
  display: block;
  box-shadow: inset 0 0 0 8px #ffffffb3;
}

.lg-outer .lg-pager:focus, .lg-outer .lg-pager:hover {
  box-shadow: inset 0 0 0 8px #fff;
}

.lg-outer .lg-caret {
  height: 0;
  vertical-align: middle;
  width: 0;
  border-top: 10px dashed;
  border-left: 10px solid #0000;
  border-right: 10px solid #0000;
  margin-left: -5px;
  display: inline-block;
  position: absolute;
  bottom: -10px;
  left: 50%;
}

.lg-fullscreen:after {
  content: "";
}

.lg-fullscreen-on .lg-fullscreen:after {
  content: "";
}

.lg-outer #lg-dropdown-overlay {
  cursor: default;
  z-index: 1081;
  opacity: 0;
  visibility: hidden;
  -o-transition: visibility 0s linear .18s, opacity .18s linear 0s;
  background-color: #00000040;
  transition: visibility 0s linear .18s, opacity .18s linear;
  position: fixed;
  inset: 0;
}

.lg-outer.lg-dropdown-active #lg-dropdown-overlay, .lg-outer.lg-dropdown-active .lg-dropdown {
  opacity: 1;
  visibility: visible;
  transition-delay: 0s;
  transform: translate3d(0, 0, 0);
}

.lg-outer .lg-dropdown {
  text-align: left;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: -webkit-transform .18s linear, visibility 0s linear .5s, opacity .18s linear;
  -o-transition: -o-transform .18s linear 0s, visibility 0s linear .5s, opacity .18s linear 0s;
  -moz-transition: -moz-transform .18s linear, visibility 0s linear .5s, opacity .18s linear;
  background-color: #fff;
  border-radius: 2px;
  margin: 0;
  padding: 10px 0;
  font-size: 14px;
  list-style-type: none;
  transition: transform .18s linear, visibility 0s linear .5s, opacity .18s linear;
  position: absolute;
  top: 50px;
  right: 0;
  transform: translate3d(0, 5px, 0);
}

.lg-outer .lg-dropdown:after {
  content: "";
  height: 0;
  width: 0;
  border: 8px solid #0000;
  border-bottom-color: #fff;
  display: block;
  position: absolute;
  top: -16px;
  right: 16px;
}

.lg-outer .lg-dropdown > li:last-child {
  margin-bottom: 0;
}

.lg-outer .lg-dropdown > li:hover .lg-icon, .lg-outer .lg-dropdown > li:hover a {
  color: #333;
}

.lg-outer .lg-dropdown a {
  color: #333;
  white-space: pre;
  padding: 4px 12px;
  font-family: Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 12px;
  display: block;
}

.lg-outer .lg-dropdown a:hover {
  background-color: #00000012;
}

.lg-outer .lg-dropdown .lg-dropdown-text {
  vertical-align: middle;
  margin-top: -3px;
  line-height: 1;
  display: inline-block;
}

.lg-outer .lg-dropdown .lg-icon {
  color: #333;
  float: none;
  height: auto;
  vertical-align: middle;
  width: auto;
  margin-right: 8px;
  padding: 0;
  font-size: 20px;
  line-height: 1;
  display: inline-block;
}

.lg-outer, .lg-outer .lg, .lg-outer .lg-inner {
  width: 100%;
  height: 100%;
}

.lg-outer #lg-share {
  position: relative;
}

.lg-outer #lg-share:after {
  content: "";
}

.lg-outer #lg-share-facebook .lg-icon {
  color: #3b5998;
}

.lg-outer #lg-share-facebook .lg-icon:after {
  content: "";
}

.lg-outer #lg-share-twitter .lg-icon {
  color: #00aced;
}

.lg-outer #lg-share-twitter .lg-icon:after {
  content: "";
}

.lg-outer #lg-share-googleplus .lg-icon {
  color: #dd4b39;
}

.lg-outer #lg-share-googleplus .lg-icon:after {
  content: "";
}

.lg-outer #lg-share-pinterest .lg-icon {
  color: #cb2027;
}

.lg-outer #lg-share-pinterest .lg-icon:after {
  content: "";
}

.group:after, .group:before {
  content: "";
  line-height: 0;
  display: table;
}

.group:after {
  clear: both;
}

.lg-outer {
  z-index: 1050;
  opacity: 0;
  -o-transition: opacity .15s ease 0s;
  transition: opacity .15s;
  position: fixed;
  top: 0;
  left: 0;
}

.lg-outer * {
  box-sizing: border-box;
}

.lg-outer.lg-visible {
  opacity: 1;
}

.lg-outer.lg-css3 .lg-item.lg-current, .lg-outer.lg-css3 .lg-item.lg-next-slide, .lg-outer.lg-css3 .lg-item.lg-prev-slide {
  -webkit-transition-duration: inherit !important;
  transition-duration: inherit !important;
  -webkit-transition-timing-function: inherit !important;
  transition-timing-function: inherit !important;
}

.lg-outer.lg-css3.lg-dragging .lg-item.lg-current, .lg-outer.lg-css3.lg-dragging .lg-item.lg-next-slide, .lg-outer.lg-css3.lg-dragging .lg-item.lg-prev-slide {
  opacity: 1;
  transition-duration: 0s !important;
}

.lg-outer.lg-grab img.lg-object {
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: -o-grab;
  cursor: -ms-grab;
  cursor: grab;
}

.lg-outer.lg-grabbing img.lg-object {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: -o-grabbing;
  cursor: -ms-grabbing;
  cursor: grabbing;
}

.lg-outer .lg {
  max-width: 100%;
  max-height: 100%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
}

.lg-outer .lg-inner {
  white-space: nowrap;
  position: absolute;
  top: 0;
  left: 0;
}

.lg-outer .lg-item {
  background: url("loading.0eae1d3a.gif") center no-repeat;
  display: none !important;
}

.lg-outer.lg-css .lg-current, .lg-outer.lg-css3 .lg-current, .lg-outer.lg-css3 .lg-next-slide, .lg-outer.lg-css3 .lg-prev-slide {
  display: inline-block !important;
}

.lg-outer .lg-img-wrap, .lg-outer .lg-item {
  text-align: center;
  width: 100%;
  height: 100%;
  display: inline-block;
  position: absolute;
}

.lg-outer .lg-img-wrap:before, .lg-outer .lg-item:before {
  content: "";
  height: 50%;
  width: 1px;
  margin-right: -1px;
  display: inline-block;
}

.lg-outer .lg-img-wrap {
  padding: 0 5px;
  position: absolute;
  inset: 0;
}

.lg-outer .lg-item.lg-complete {
  background-image: none;
}

.lg-outer .lg-item.lg-current {
  z-index: 1060;
}

.lg-outer .lg-image {
  vertical-align: middle;
  max-width: 100%;
  max-height: 100%;
  display: inline-block;
  width: auto !important;
  height: auto !important;
}

.lg-outer.lg-show-after-load .lg-item .lg-object, .lg-outer.lg-show-after-load .lg-item .lg-video-play {
  opacity: 0;
  -o-transition: opacity .15s ease 0s;
  transition: opacity .15s;
}

.lg-outer.lg-show-after-load .lg-item.lg-complete .lg-object, .lg-outer.lg-show-after-load .lg-item.lg-complete .lg-video-play {
  opacity: 1;
}

.lg-outer .lg-empty-html, .lg-outer.lg-hide-download #lg-download {
  display: none;
}

.lg-backdrop {
  z-index: 1040;
  opacity: 0;
  -o-transition: opacity .15s ease 0s;
  background-color: #000;
  transition: opacity .15s;
  position: fixed;
  inset: 0;
}

.lg-backdrop.in {
  opacity: 1;
}

.lg-css3.lg-no-trans .lg-current, .lg-css3.lg-no-trans .lg-next-slide, .lg-css3.lg-no-trans .lg-prev-slide {
  -o-transition: none 0s ease 0s !important;
  transition: none !important;
}

.lg-css3.lg-use-css3 .lg-item, .lg-css3.lg-use-left .lg-item {
  backface-visibility: hidden;
}

.lg-css3.lg-fade .lg-item {
  opacity: 0;
}

.lg-css3.lg-fade .lg-item.lg-current {
  opacity: 1;
}

.lg-css3.lg-fade .lg-item.lg-current, .lg-css3.lg-fade .lg-item.lg-next-slide, .lg-css3.lg-fade .lg-item.lg-prev-slide {
  -o-transition: opacity .1s ease 0s;
  transition: opacity .1s;
}

.lg-css3.lg-slide.lg-use-css3 .lg-item {
  opacity: 0;
}

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-prev-slide {
  transform: translate3d(-100%, 0, 0);
}

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-next-slide {
  transform: translate3d(100%, 0, 0);
}

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-current {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-current, .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-next-slide, .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-prev-slide {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, .25, 1), opacity .1s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, .25, 1) 0s, opacity .1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, .25, 1), opacity .1s;
  transition: transform 1s cubic-bezier(0, 0, .25, 1), opacity .1s;
}

.lg-css3.lg-slide.lg-use-left .lg-item {
  opacity: 0;
  position: absolute;
  left: 0;
}

.lg-css3.lg-slide.lg-use-left .lg-item.lg-prev-slide {
  left: -100%;
}

.lg-css3.lg-slide.lg-use-left .lg-item.lg-next-slide {
  left: 100%;
}

.lg-css3.lg-slide.lg-use-left .lg-item.lg-current {
  opacity: 1;
  left: 0;
}

.lg-css3.lg-slide.lg-use-left .lg-item.lg-current, .lg-css3.lg-slide.lg-use-left .lg-item.lg-next-slide, .lg-css3.lg-slide.lg-use-left .lg-item.lg-prev-slide {
  -o-transition: left 1s cubic-bezier(0, 0, .25, 1) 0s, opacity .1s ease 0s;
  transition: left 1s cubic-bezier(0, 0, .25, 1), opacity .1s;
}

html {
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
}

main {
  display: block;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace;
  font-size: 1em;
}

a {
  background-color: #0000;
}

abbr[title] {
  border-bottom: none;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp {
  font-family: monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

img {
  border-style: none;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring, [type="button"]:-moz-focusring, [type="reset"]:-moz-focusring, [type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: .35em .75em .625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  max-width: 100%;
  white-space: normal;
  padding: 0;
  display: table;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template, [hidden] {
  display: none;
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  src: url("montserrat-v14-latin_cyrillic-regular.0382bd1a.eot");
  src: local(Montserrat Regular), local(Montserrat-Regular), url("montserrat-v14-latin_cyrillic-regular.0382bd1a.eot#iefix") format("embedded-opentype"), url("montserrat-v14-latin_cyrillic-regular.aadd922d.woff2") format("woff2"), url("montserrat-v14-latin_cyrillic-regular.f301bfff.woff") format("woff"), url("montserrat-v14-latin_cyrillic-regular.94557bc4.ttf") format("truetype"), url("montserrat-v14-latin_cyrillic-regular.59d7cab2.svg#Montserrat") format("svg");
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 700;
  src: url("montserrat-v14-latin_cyrillic-700.adc75d33.eot");
  src: local(Montserrat Bold), local(Montserrat-Bold), url("montserrat-v14-latin_cyrillic-700.adc75d33.eot#iefix") format("embedded-opentype"), url("montserrat-v14-latin_cyrillic-700.dc9e795c.woff2") format("woff2"), url("montserrat-v14-latin_cyrillic-700.c2be36e3.woff") format("woff"), url("montserrat-v14-latin_cyrillic-700.ca9fc0ea.ttf") format("truetype"), url("montserrat-v14-latin_cyrillic-700.b455e6b7.svg#Montserrat") format("svg");
}

h1 {
  margin: 10px 0;
  padding: 10px;
  font-family: Montserrat, Arial, sans-serif;
  font-size: 2rem;
  line-height: 1.87rem;
}

h2 {
  margin: 8px 0;
  padding: 10px;
  font-family: Montserrat, Arial, sans-serif;
  font-size: 1.34rem;
  line-height: 1.5rem;
}

h3 {
  text-transform: uppercase;
  margin: 6px 0;
  padding: 10px;
  font-family: Montserrat, Arial, sans-serif;
  font-size: .85rem;
  line-height: .93rem;
}

p {
  margin: 0;
  padding: 10px;
  font-size: 1rem;
  line-height: 1.5rem;
}

a {
  color: #000;
  text-decoration: none;
}

a:hover {
  color: #d3d3d3;
}

.link-white {
  color: #fff;
}

.link-white:hover {
  color: #d3d3d3;
}

strong {
  font-weight: 400;
}

.text-r {
  text-align: right;
}

.text-l {
  text-align: left;
}

.text-c {
  text-align: center;
}

.white {
  color: #fff;
}

.big {
  font-size: 2.35rem;
  line-height: 2.5rem;
}

.white-bg {
  background-color: #fff;
}

.dark-shade100 {
  background-color: #1f2041;
}

.dark-shade75 {
  background-color: #1f2041bf;
}

.dark-shade50 {
  background-color: #1f204180;
}

.dark-shade25 {
  background-color: #1f204140;
}

.dark-shade5 {
  background-color: #1f20410d;
}

.purple {
  background-color: #bc9cff;
}

.green {
  background-color: #6fcf97;
}

.hamburger {
  z-index: 100;
  width: 40px;
  height: 40px;
  cursor: pointer;
  display: inline-block;
  position: relative;
  top: 35px;
  right: 40px;
}

.hamburger__layers, .hamburger__layers:after, .hamburger__layers:before {
  width: 40px;
  height: 4px;
  z-index: 10;
  background-color: #fff;
  border-radius: 4px;
  transition-property: transform;
  transition-duration: .15s;
  transition-timing-function: ease;
  position: absolute;
  top: 2px;
}

.hamburger__layers:before {
  content: "";
  top: 10px;
}

.hamburger__layers:after {
  content: "";
  top: 20px;
}

.hamburger__layers--disclosed {
  background-color: #000;
  transform: translate3d(0, 10px, 0)rotate(45deg);
}

.hamburger__layers--disclosed:before {
  opacity: 0;
}

.hamburger__layers--disclosed:after {
  background-color: #000;
  transform: translate3d(0, -20px, 0)rotate(-90deg);
}

.menu {
  z-index: 2;
  background-color: #ebebeb;
  justify-content: center;
  align-items: center;
  display: none;
  position: fixed;
  inset: 0;
}

.menu--disclosed {
  display: flex;
}

.menu__button {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 5%;
  right: 5%;
}

.menu__list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.menu__link {
  color: #000;
  font-size: 3.5rem;
}

.menu__link:hover {
  color: gray;
}

.menu__link:active {
  color: #000;
}

.tns-outer {
  padding: 0 !important;
}

.tns-outer [hidden] {
  display: none !important;
}

.tns-outer [aria-controls], .tns-outer [data-action] {
  cursor: pointer;
}

.tns-slider {
  transition: all;
}

.tns-slider > .tns-item {
  box-sizing: border-box;
}

.tns-horizontal.tns-subpixel {
  white-space: nowrap;
}

.tns-horizontal.tns-subpixel > .tns-item {
  vertical-align: top;
  white-space: normal;
  display: inline-block;
}

.tns-horizontal.tns-no-subpixel:after {
  content: "";
  clear: both;
  display: table;
}

.tns-horizontal.tns-no-subpixel > .tns-item {
  float: left;
}

.tns-horizontal.tns-carousel.tns-no-subpixel > .tns-item {
  margin-right: -100%;
}

.tns-no-calc {
  position: relative;
  left: 0;
}

.tns-gallery {
  min-height: 1px;
  position: relative;
  left: 0;
}

.tns-gallery > .tns-item {
  transition: transform, opacity;
  position: absolute;
  left: -100%;
}

.tns-gallery > .tns-slide-active {
  position: relative;
  left: auto !important;
}

.tns-gallery > .tns-moving {
  transition: all .25s;
}

.tns-autowidth {
  display: inline-block;
}

.tns-lazy-img {
  opacity: .6;
  transition: opacity .6s;
}

.tns-lazy-img.tns-complete {
  opacity: 1;
}

.tns-ah {
  transition: height;
}

.tns-ovh {
  overflow: hidden;
}

.tns-visually-hidden {
  position: absolute;
  left: -10000em;
}

.tns-transparent {
  opacity: 0;
  visibility: hidden;
}

.tns-fadeIn {
  opacity: 1;
  filter: alpha(opacity= 100);
  z-index: 0;
}

.tns-normal, .tns-fadeOut {
  opacity: 0;
  filter: alpha(opacity= 0);
  z-index: -1;
}

.tns-vpfix {
  white-space: nowrap;
}

.tns-vpfix > div, .tns-vpfix > li {
  display: inline-block;
}

.tns-t-subp2 {
  width: 310px;
  height: 10px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
}

.tns-t-ct {
  width: 2333.33%;
  width: -webkit-calc(100% * 70 / 3);
  width: -moz-calc(100% * 70 / 3);
  width: 2333.33%;
  position: absolute;
  right: 0;
}

.tns-t-ct:after {
  content: "";
  clear: both;
  display: table;
}

.tns-t-ct > div {
  width: 1.42857%;
  width: -webkit-calc(100% / 70);
  width: -moz-calc(100% / 70);
  width: 1.42857%;
  height: 10px;
  float: left;
}

.social {
  justify-content: center;
  display: flex;
  position: absolute;
  bottom: 30px;
}

.social--fluid {
  margin-top: 75px;
  position: static;
}

.social__link {
  margin-right: 10px;
}

.social__link--fluid {
  width: 20%;
  justify-content: center;
  margin: 0;
  display: flex;
}

.social__icon, .social__soundcloud, .social__youtube, .social__instagram, .social__vk, .social__facebook {
  width: 30px;
  height: 30px;
}

.social__facebook {
  background: url("facebook-black.7f9011c3.svg") 50% no-repeat;
}

.social__facebook--fluid {
  width: 100%;
  background: #3c5998 url("facebook-white.6826f031.svg") 50% no-repeat;
}

.social__vk {
  background: url("vk-black.7c01ae8f.svg") 50% no-repeat;
}

.social__vk--fluid {
  width: 100%;
  background: #47709c url("vk-white.cad26c8f.svg") 50% no-repeat;
}

.social__instagram {
  background: url("instagram-black.16881f63.svg") 50% no-repeat;
}

.social__instagram--fluid {
  width: 100%;
  background: #5851dc url("instagram-white.7c8794cb.svg") 50% no-repeat;
}

.social__youtube {
  background: url("youtube-black.b494fbb9.svg") 50% no-repeat;
}

.social__youtube--fluid {
  width: 100%;
  background: #e62017 url("youtube-white.6e45e89c.svg") 50% no-repeat;
}

.social__soundcloud {
  background: url("soundcloud-black.05b35a04.svg") 50% no-repeat;
}

.social__soundcloud--fluid {
  width: 100%;
  background: #f95500 url("soundcloud-white.44ff9a57.svg") 50% no-repeat;
}

.jumping-buttons {
  z-index: 100;
  position: fixed;
  bottom: 80px;
  right: 80px;
}

.jumping-buttons__input {
  z-index: -1;
  opacity: 0;
  position: relative;
}

.jumping-buttons__label {
  cursor: pointer;
  width: 60px;
  height: 60px;
  background-color: #8c8c8c;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  animation: 2s ease-out infinite pulse;
  display: flex;
  position: absolute;
  box-shadow: 0 0 20px #0000004d;
}

.jumping-buttons__label:hover {
  animation: none;
}

.jumping-buttons__message {
  width: 35px;
  height: 32px;
  stroke-width: 1.5px;
  background: #8c8c8c url("message.8d41234a.svg") 50% no-repeat;
  transition: opacity .3s ease-in-out;
}

.jumping-buttons__close {
  opacity: 0;
  visibility: hidden;
  width: 16px;
  height: 16px;
  background: url("close.1912ebb8.svg") 50% no-repeat;
  transition: opacity .3s ease-in-out;
  position: absolute;
}

.jumping-buttons__tooltip {
  white-space: nowrap;
  color: #fff;
  opacity: 0;
  background: #292929;
  border-radius: 3px;
  padding: 6px 9px;
  font-size: 13px;
  transition: all .1s linear;
  position: absolute;
  top: 50%;
  right: 70px;
  transform: translateX(0%)translateY(-50%);
}

.jumping-buttons__tooltip:after {
  content: "";
  width: 0;
  height: 0;
  border: 10px solid #0000;
  border-left-color: #292929;
  position: absolute;
  top: 50%;
  right: -20px;
  transform: translateY(-50%);
}

.jumping-buttons__icon {
  opacity: 0;
  visibility: hidden;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  transition: all .2s ease-in-out;
  display: block;
  position: absolute;
  left: 50%;
  box-shadow: 0 0 20px #0000004d;
}

.jumping-buttons__icon:hover .jumping-buttons__tooltip {
  opacity: .9;
}

.jumping-buttons__icon--telegram {
  background: #0087d0 url("telegram.d8162546.svg") 50% no-repeat;
}

.jumping-buttons__icon--whatsapp {
  background: #0087d0 url("whatsapp.6caed016.svg") 50% no-repeat;
}

.jumping-buttons__icon--vk {
  background: #0087d0 url("vk-blue.4c4516c2.svg") 50% no-repeat;
}

.jumping-buttons__icon--mail {
  background: #0087d0 url("mail.3ab351aa.svg") 50% no-repeat;
}

.jumping-buttons__icon--phone {
  background: #0087d0 url("instagram.204ba792.svg") 50% no-repeat;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  25% {
    transform: scale(1.1);
  }

  50% {
    transform: scale(1);
  }
}

.jumping-buttons__input:checked + .jumping-buttons__label {
  background-color: #fff;
  animation: none;
}

.jumping-buttons__input:checked + .jumping-buttons__label .jumping-buttons__message {
  visibility: hidden;
  opacity: 0;
}

.jumping-buttons__input:checked + .jumping-buttons__label .jumping-buttons__close, .jumping-buttons__input:checked ~ .jumping-buttons__icon {
  visibility: visible;
  opacity: 1;
}

.jumping-buttons__input:checked ~ .jumping-buttons__icon--telegram {
  background: #0087d0 url("telegram.d8162546.svg") 50% no-repeat;
  transform: translate(0, -135%);
}

.jumping-buttons__input:checked ~ .jumping-buttons__icon--whatsapp {
  background: #0087d0 url("whatsapp.6caed016.svg") 50% no-repeat;
  transform: translate(0, -255%);
}

.jumping-buttons__input:checked ~ .jumping-buttons__icon--vk {
  background: #0087d0 url("vk-blue.4c4516c2.svg") 50% no-repeat;
  transform: translate(0, -375%);
}

.jumping-buttons__input:checked ~ .jumping-buttons__icon--mail {
  background: #0087d0 url("mail.3ab351aa.svg") 50% no-repeat;
  transform: translate(0, -495%);
}

.jumping-buttons__input:checked ~ .jumping-buttons__icon--phone {
  background: #0087d0 url("phone.ac92f0c2.svg") 50% no-repeat;
  transform: translate(0, -615%);
}

.jumping-buttons__input:checked ~ .jumping-buttons__icon--instagram {
  background: #0087d0 url("instagram.204ba792.svg") 50% no-repeat;
  transform: translate(0, -735%);
}

html {
  font-size: 16px;
}

@media (max-width: 992px) {
  html {
    font-size: 15px;
  }
}

@media (max-width: 576px) {
  html {
    font-size: 14px;
  }
}

body {
  min-height: 100vh;
  background-color: #fff;
  font-family: Montserrat, Arial, sans-serif;
  font-size: 16px;
  line-height: 1.5;
}

*, :before, :after {
  box-sizing: border-box;
}

.fs-3rem {
  font-size: 3rem;
}

.center {
  text-align: center;
}

.mt-25 {
  margin-top: 25px;
}

.mt-50 {
  margin-top: 50px;
}

.stars {
  font-size: 1.7rem;
}

.stripper, .stripper:after, .stripper:before {
  z-index: 0;
  width: 2px;
  height: 25px;
  background-color: gray;
  border-radius: 4px;
  margin: 30px 0;
  display: inline-block;
  position: relative;
  transform: rotate(45deg);
}

.stripper:before {
  content: "";
  position: absolute;
  top: -40px;
  left: 10px;
  transform: rotate(0);
}

.stripper:after {
  content: "";
  position: absolute;
  top: -50px;
  left: 20px;
  transform: rotate(0);
}

.fluid {
  width: 100%;
  background-color: #fff;
}

.fluid--black {
  color: #fff;
  background-color: #000;
}

.fluid--fixed {
  z-index: 100;
  position: fixed;
}

.fluid--p104 {
  padding-top: 104px;
}

.wrap {
  max-width: 1200px;
  margin: 0 auto;
}

.header {
  height: 104px;
  flex-wrap: wrap;
  justify-content: space-between;
  display: flex;
}

.header__logo {
  align-items: center;
  display: flex;
}

.header__link {
  text-transform: uppercase;
  color: #fff;
  margin-left: 40px;
  font-size: 1.12rem;
  font-weight: 700;
}

.about {
  justify-content: space-between;
  padding: 75px 0;
  display: flex;
}

@media (max-width: 992px) {
  .about {
    flex-wrap: wrap;
    justify-content: center;
  }
}

.about__text {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

@media (max-width: 992px) {
  .about__pic {
    width: 100%;
  }
}

.gallery {
  padding: 75px 0;
}

#lightgallery {
  justify-content: space-between;
  display: flex;
}

.lightgallery__full-img {
  text-align: center;
}

@media (max-width: 1200px) {
  .lightgallery__img {
    width: 90%;
  }
}

.slider {
  position: relative;
}

.slider__slide {
  text-align: center;
  position: relative;
}

.slider__pic {
  width: 80%;
}

.slider__slide--video:hover {
  cursor: pointer;
}

.slider__slide--video:hover .slider__svg-icon-bg {
  fill: red;
}

.slider__svg-icon {
  position: absolute;
  top: calc(50% - 24px);
  left: calc(50% - 34px);
}

.slider__svg-icon-bg {
  cursor: pointer;
  fill: #212121;
}

.slider__svg-icon-bg:hover {
  fill: red;
}

.slider__controls, .reviews__controls {
  width: 100%;
  position: absolute;
  top: calc(50% - 20px);
}

.slider__arrow, .slider__right, .reviews__right, .slider__left, .reviews__left {
  cursor: pointer;
  width: 40px;
  height: 40px;
  z-index: 90;
  border-radius: 50%;
  outline: none;
  position: absolute;
}

@media (max-width: 576px) {
  .slider__arrow, .slider__right, .reviews__right, .slider__left, .reviews__left {
    width: 30px;
    height: 30px;
  }
}

.slider__left, .reviews__left {
  background: #eee url("arrow.bcc5c9ec.svg") 50% no-repeat;
  left: 40px;
  transform: translate(-55%, -50%)rotate(180deg);
}

@media (max-width: 768px) {
  .slider__left, .reviews__left {
    left: 30px;
  }
}

@media (max-width: 576px) {
  .slider__left, .reviews__left {
    left: 19px;
  }
}

.slider__right, .reviews__right {
  background: #eee url("arrow.bcc5c9ec.svg") 50% no-repeat;
  right: 0;
  transform: translate(-55%, -50%);
}

@media (max-width: 768px) {
  .slider__right, .reviews__right {
    right: -15px;
  }
}

@media (max-width: 576px) {
  .slider__right, .reviews__right {
    right: -16px;
  }
}

.you-tube__relation {
  width: 80%;
  margin: 0 auto;
  position: relative;
}

.you-tube__ratio {
  height: 0;
  padding-top: 66.6%;
}

.you-tube__content {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.slider__nav, .reviews__nav {
  justify-content: center;
  margin-top: 15px;
  display: flex;
}

.slider__dot, .reviews__dot {
  cursor: pointer;
  width: 10px;
  height: 10px;
  background-color: #c7c7c7;
  border: 0;
  border-radius: 50%;
  outline: 0;
  margin-right: 15px;
  padding: 0;
  transition: all .2s ease-in-out;
}

.slider__dot:hover, .reviews__dot:hover, .tns-nav-active {
  background-color: #222;
}

.reviews {
  padding-top: 30px;
  position: relative;
}

.reviews__review {
  text-align: center;
}

.reviews__text {
  max-width: 60%;
  display: inline-block;
}

@media (max-width: 768px) {
  .reviews__text {
    max-width: 100%;
  }
}

.reviews__person {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.reviews__wrap {
  width: 150px;
  height: 150px;
  background-color: #000;
  border-radius: 50%;
  justify-content: center;
  margin: 30px 0;
  display: flex;
  overflow: hidden;
}

.reviews__img {
  width: auto;
  height: inherit;
}

.social-icon, .instagram, .vk, .facebook {
  width: 33px;
  height: 33px;
}

.facebook {
  background: url("facebook.26d73b35.svg") 50% no-repeat;
}

.vk {
  background: url("vk.d9bb3b0c.svg") 50% no-repeat;
}

.instagram {
  background: url("instagram.204ba792.svg") 50% no-repeat;
}

.reviews__nav {
  margin-top: 30px;
}

.reviews__dot {
  background-color: #fff;
}

.reviews__dot:hover {
  background-color: #c7c7c7;
}

.reviews__nav .tns-nav-active {
  background-color: #222;
}

.skills {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.skills__item {
  max-width: 67%;
  display: flex;
}

@media (max-width: 768px) {
  .skills__item {
    max-width: 95%;
  }
}

.skills__img {
  align-items: center;
  margin-right: 30px;
  display: flex;
}

.skills__pic {
  width: 65px;
  height: 65px;
}

@media (max-width: 768px) {
  .skills__pic {
    width: 50px;
    height: 50px;
  }
}

.contacts {
  flex-direction: column;
  align-items: center;
  display: flex;
}

/*# sourceMappingURL=index.93fb35c4.css.map */
