//main elements

h1 {
    font-family: $monsterrat;
    font-size: 2rem;
    line-height: 1.87rem;
    margin: 10px 0;
    padding: 10px;
}

h2 {
    font-family: $monsterrat;
    font-size: 1.34rem;
    line-height: 1.5rem;
    margin: 8px 0;
    padding: 10px;
}

h3 {
    font-family: $monsterrat;
    font-size: 0.85rem;
    text-transform: uppercase;
    margin: 6px 0;
    line-height: 0.93rem;
    padding: 10px;
}

p {
    padding: 10px;
    margin: 0;
    font-size: 1rem;
    line-height: 1.5rem;
}

a {
    text-decoration: none;
    color: black;
    &:hover {
        color: lightgrey;
    }
}

.link-white {
    color: white;
    &:hover {
        color: lightgrey;
    }
}

strong {
    font-weight: 400;
}
//align

.text-r {
    text-align: right;
}

.text-l {
    text-align: left;
}

.text-c {
    text-align: center;
}
//colors
.white {
    color: white;
}

//sizes
.big {
    font-size: 2.35rem;
    line-height: 2.5rem;
}