//Mixins


@function rem($size) {
    @return ($size / 16) * 1rem;
}


//desktop first method
@mixin breakpoint-dfirst ($point) {
    @if $point == desktop {
        @media (max-width: 1200px) { @content; }
    } @else if $point == lg-tablet {
        @media (max-width: 992px) { @content; }
    } @else if $point == tablet {
        @media (max-width: 768px) { @content; }
    } @else if $point == phone {
        @media (max-width: 576px) { @content; }
    } @else if $point {
        @media (max-width: $point) { @content; }
    }
}

//mobile first method
@mixin breakpoint-mfirst ($point) {
    @if $point == desktop {
        @media (min-width: 1200px) { @content; }
    } @else if $point == lg-tablet {
        @media (min-width: 992px) { @content; }
    } @else if $point == tablet {
        @media (min-width: 768px) { @content; }
    } @else if $point == phone {
        @media (min-width: 576px) { @content; }
    } @else if $point {
        @media (min-width: $point) { @content; }
    }
}