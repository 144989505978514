
/* montserrat-regular - latin_cyrillic */

@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/montserrat-v14-latin_cyrillic-regular.eot'); /* IE9 Compat Modes */
    src: local('Montserrat Regular'), local('Montserrat-Regular'),
         url('../fonts/montserrat-v14-latin_cyrillic-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/montserrat-v14-latin_cyrillic-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/montserrat-v14-latin_cyrillic-regular.woff') format('woff'), /* Modern Browsers */
         url('../fonts/montserrat-v14-latin_cyrillic-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/montserrat-v14-latin_cyrillic-regular.svg#Montserrat') format('svg'); /* Legacy iOS */
}

/* montserrat-700 - latin_cyrillic */

@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/montserrat-v14-latin_cyrillic-700.eot'); /* IE9 Compat Modes */
    src: local('Montserrat Bold'), local('Montserrat-Bold'),
         url('../fonts/montserrat-v14-latin_cyrillic-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/montserrat-v14-latin_cyrillic-700.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/montserrat-v14-latin_cyrillic-700.woff') format('woff'), /* Modern Browsers */
         url('../fonts/montserrat-v14-latin_cyrillic-700.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/montserrat-v14-latin_cyrillic-700.svg#Montserrat') format('svg'); /* Legacy iOS */
  }
