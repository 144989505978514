.white-bg {
    background-color: $white;
}

.dark-shade100 {
    background-color: $dark-shade-100;
}

.dark-shade75 {
    background-color: $dark-shade-75;
}

.dark-shade50 {
    background-color: $dark-shade-50;
}

.dark-shade25 {
    background-color: $dark-shade-25;
}

.dark-shade5 {
    background-color: $dark-shade-05;
}

.purple {
    background-color: $purple;
}

.green {
    background-color: $green;
}