$content-size: 1440px;


$main-bgcolor: #ffffff;

//colors
$dark-shade-100: rgba(31, 32, 65, 1);
$dark-shade-75: rgba(31, 32, 65, 0.75);
$dark-shade-50: rgba(31, 32, 65, 0.5);
$dark-shade-25: rgba(31, 32, 65, 0.25);
$dark-shade-1: rgba(31, 32, 65, 0.1);
$dark-shade-05: rgba(31, 32, 65, 0.05);


$purple: #BC9CFF;
$lilac: #8BA4F9;
$green: #6FCF97;
$white: #ffffff;
//fonts

$monsterrat: 'Montserrat', 'Arial', sans-serif;